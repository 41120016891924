import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { size } from 'lodash';
import cx from 'classnames';

// core components
import { useConfirm } from 'material-ui-confirm';
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PillButton from 'components/PillButton';

import FolderIcon from '@material-ui/icons/Folder';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VideocamIcon from '@material-ui/icons/Videocam';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import LinkIcon from '@material-ui/icons/Link';

//staffing components
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

import './projectCard.scss';

//actions & selectors
import { selectProject } from 'redux/selectors/staffing/getProject';
import { openAddProjectToDealDialog, deleteProject } from 'redux/actions/staffingActions';
import ProjectStatusView from '../ProjectDetailCard/ProjectStatusView';

const useStyles = makeStyles(theme => ({
  jobTitle: {
    fontSize: 15,
    fontWeight: '500',
    color: 'black',
    marginBottom: 0,
  },
  jobSubtitle: {
    fontSize: 13,
    fontWeight: '400',
    color: 'black',
  },
  jobDescription: {
    fontSize: 14,
    fontWeight: '300',
    color: '#5E5E5E',
  },
  jobInfoCols: {
    paddingTop: 5,
  },
  jobInfoColTitle: {
    fontSize: 11,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 0,
    textTransform: 'uppercase',
  },
  jobInfoColSubTitle: {
    fontSize: 16,
    fontWeight: '400',
    color: '#333333',
  },
  jobSeatItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  controllerButton: {
    fontSize: 14,
  },
  signedLabel: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.75,
    padding: '0px 10px',
    color: '#898989',
  },
  rejectedWrapper: {
    display: 'flex',
    gap: 10,
  },
  rejectedIcon: {
    margin: 'auto',
    marginLeft: 6,
    cursor: 'pointer',
    color: '#898989',
  },

  infoLabel: {
    fontSize: 11,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
  },
  moreDetailsBtnIcon: {
    fontSize: 15,
    marginRight: 5,
  },

  rolesFilledSection: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  rolesFilledTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: '#5E5E5E',
    marginBottom: 0,
  },
  resyncMessage: {
    color: 'red',
    fontSize: 14,
    fontWeight: '500',
    marginBottom: 0,
  },
}));

const ProjectCard = ({ id }) => {
  const confirm = useConfirm();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const getProject = selectProject();
  const project = useSelector(state => getProject(state, id));

  if (!project) {
    return null;
  }

  const { eventName, notes, formattedStartDate, formattedEndDate, dealName, clientName } = project;

  const confirmDeleteProject = () => {
    confirm({
      description: `You are about to delete this project. Are you sure?`,
    }).then(() => {
      dispatch(deleteProject(id, project.dealId));
    });
  };

  const handleDetailNavigate = e => {
    e.preventDefault();
    history.push(`/admin/staffing/projects/detail/${id}`);
  };

  // MENU CONTROL OPTIONS
  const menuOptions = [
    // {
    //   title: "Invoice",
    //   onItemClick: () => {},
    // },
    // {
    //   title: "Re-create assets",
    //   onItemClick: () => {},
    // },
    {
      title: 'View Jobs',
      onItemClick: handleDetailNavigate,
    },
    {
      title: 'Edit',
      onItemClick: () => {
        dispatch(openAddProjectToDealDialog(true, project.dealId, project.id));
      },
      disabled: project.isCreatingAttendeeAssets,
    },
    {
      title: 'Delete',
      onItemClick: confirmDeleteProject,
      disabled: project.isCreatingAttendeeAssets,
    },
  ];

  return (
    <Card className={cx('deal-project-card position-relative')}>
      <CardContent>
        <div className="d-flex justify-content-between align-items-start">
          <a
            className="deal-project-header position-relative pr-1 d-block"
            onClick={handleDetailNavigate}
            href={`/admin/staffing/projects/detail/${id}`}
          >
            <p className={classes.jobTitle}>{eventName}</p>
            <div className={classes.jobSubtitle}>
              <div>{dealName}</div>
              <div>{clientName}</div>
              <div>
                {formattedStartDate} - {formattedEndDate}
              </div>
            </div>
            <p className={classes.jobSubtitle}></p>
            <p className={classes.jobDescription}>{notes}</p>
          </a>
          <div className="d-flex justify-content-end align-items-start flex-1">
            <div style={{ marginRight: -8 }}>
              <ProjectStatusView status={project.status} statusError={project.statusError} />
            </div>
            <div className="position-relative" style={{ marginTop: -12, marginRight: -12 }}>
              <SelectMenu options={menuOptions} menuContainerClassName="position-relative" />
            </div>
          </div>
        </div>
        <Divider />

        <Grid container spacing={5} className={classes.jobInfoCols}>
          <Grid item xs={3}>
            <p className={classes.jobInfoColTitle}>Amount</p>
            <p className={classes.jobInfoColSubTitle}>{project.formattedProjectContractValue}</p>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.jobInfoColTitle}>Type</p>
            <p className={classes.jobInfoColSubTitle}>{project.displayLocationType}</p>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.jobInfoColTitle}>Methodology</p>
            <p className={classes.jobInfoColSubTitle}>{project.projectMethodologyName}</p>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.jobInfoColTitle}>Service Type</p>
            <p className={classes.jobInfoColSubTitle}>{project.serviceType}</p>
          </Grid>
        </Grid>

        <div>
          <p className={classes.infoLabel}>MORE DETAILS</p>

          <div className="d-flex align-items-center flex-wrap">
            <PillButton
              type="plainText"
              text={project.isPublic ? 'Public' : 'Private'}
              disabled
            />
            {project.isFree && <PillButton type="plainText" text="Free" disabled />}
            {project.isCreatingAttendeeAssets && (
              <PillButton type="plainText" text="Creating Attendee Assets" disabled loading />
            )}
            {project.venue && (
              <PillButton type="plainText" text={project.venue} Icon={LocationOnIcon} />
            )}
            {['hybrid', 'virtual'].includes(project.locationType) && project.zoomLink && (
              <PillButton
                type="externalUrl"
                externalUrl={project.zoomLink}
                text="Zoom Link"
                Icon={VideocamIcon}
              />
            )}
            {project.projectBrief?.webViewLink && (
              <PillButton
                type="externalUrl"
                externalUrl={project.projectBrief.webViewLink}
                text="Project Brief"
                Icon={LinkIcon}
              />
            )}
            {project.checklistDoc?.webViewLink && (
              <PillButton
                type="externalUrl"
                externalUrl={project.checklistDoc.webViewLink}
                text="Checklist"
                Icon={LinkIcon}
              />
            )}
            {project.runOfShowDoc?.webViewLink && (
              <PillButton
                type="externalUrl"
                externalUrl={project.runOfShowDoc.webViewLink}
                text="ROS"
                Icon={LinkIcon}
              />
            )}
            {project.calendarEvent?.webViewLink && (
              <PillButton
                type="externalUrl"
                externalUrl={project.calendarEvent.webViewLink}
                text="Calendar"
                Icon={EventIcon}
              />
            )}
            {project.projectFolder?.webViewLink && (
              <PillButton
                type="externalUrl"
                externalUrl={project.projectFolder.webViewLink}
                text="Project Folder"
                Icon={FolderIcon}
              />
            )}
          </div>
        </div>

        <Divider />

        <div className={classes.rolesFilledSection}>
          <div className="d-flex align-items-center">
            <PersonIcon className={classes.moreDetailsBtnIcon} />
            <p className={classes.rolesFilledTitle}>
              {project.numberOfFilledJobs}/{size(project.jobs)} Roles Filled
            </p>
          </div>
          {project.remindUserToRunResyncCalendar && (
            <p className={classes.resyncMessage}>Needs Resync Cal</p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectCard;

ProjectCard.propTypes = {
  id: PropTypes.string.isRequired,
};
