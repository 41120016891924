import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OverlaySpinner from '../components/OverlaySpinner';
import DelayedRender from '../components/DelayedRender/DelayedRender';
import Spinner from '../components/Spinner';
import { useInternalApiClient } from 'hooks/useInternalApiClient';
import { Paper, Typography } from '@material-ui/core';
import moment from 'moment-timezone'; // Add this import
import { chain } from 'lodash';
import VolunteerSignupCard from 'components/Staffing/Volunteers/VolunteerSignupCard';
import { renderHtmlStringSimple } from 'utils/renderHtmlString';

import VCLogo from '../assets/img/vc-logo-black-horizontal.svg';

/**
 * Renders a feedback form for a session outside of the normal
 * session layout so it can be visited independently and also
 * allows for completely anonyous feedback
 */
export default function VolunteerSignup() {
  const { projectId } = useParams();
  const internalApiClient = useInternalApiClient();
  const [projectData, setProjectData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPageData = useCallback(
    (waitTime = 0) => {
      setIsLoading(true);
      setTimeout(() => {
        internalApiClient.volunteer
          .getProjectData(projectId)
          .then(setProjectData)
          .catch(setError)
          .finally(() => setIsLoading(false));
      }, waitTime);
    },
    [projectId, internalApiClient],
  );

  const volunteerRoles = chain(projectData?.volunteerRoles || {})
    .orderBy(['displayOrder', 'name'])
    .value();

  const history = useHistory();

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  const formatDateTime = dateTimeString => {
    return moment(dateTimeString)
      .tz('America/Chicago') // Set to Central timezone
      .format('MMMM Do, YYYY - h:mma [CT]'); // Format as requested
  };

  const formatShortDateTime = dateTimeString => {
    return moment(dateTimeString)
      .tz('America/Chicago') // Set to Central timezone
      .format('M/D/YYYY h:mma [CT]'); // Format as requested
  };

  const isFilled = roleId => {
    const role = projectData.volunteerRoles[roleId];
    return role.seatsFilled >= role.seats;
  };

  if (!isLoading && isEmpty(projectData)) {
    history.push('/auth/404');
  }

  if (isLoading && !projectData) {
    return (
      <div className="position-fixed h-100 w-100 d-flex justify-content-center align-items-center">
        <DelayedRender>
          <Spinner />
        </DelayedRender>
      </div>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>Voltage Control | Volunteer Signup</title>
      </Helmet>
      {isLoading && <OverlaySpinner fullscreen />}
      <div className="container-fluid mt-4" style={{ maxWidth: '800px' }}>
        <div className="row mb-2">
          <div className="col-12 text-center">
            <img src={VCLogo} alt="VC Logo" className="mb-4" width="300" height="auto" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Paper className="p-4">
              <Typography variant="h5" className="mb-4 fw-500">
                Volunteer Signup
              </Typography>
              <div className="row text-left">
                <div className="col-12">
                  <Typography variant="body1">
                    <span className="fw-500">Event:</span> {projectData.name}
                  </Typography>
                  <Typography variant="body1">
                    <span className="fw-500">Date & Time:</span>{' '}
                    {formatDateTime(`${projectData.startDate}T${projectData.startTimeCT}`)}
                  </Typography>
                  <Typography variant="body1" className="mb-4">
                    <span className="fw-500">Location:</span> {projectData.location}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="mb-6"
                    dangerouslySetInnerHTML={renderHtmlStringSimple(projectData?.description || '')}
                  />

                  {volunteerRoles.length > 0 ? (
                    volunteerRoles.map((role, index) => (
                      <VolunteerSignupCard
                        key={String(index)}
                        projectId={projectId}
                        role={role}
                        isFilled={isFilled}
                        formattedDateTime={formatShortDateTime()}
                        refreshPageData={getPageData}
                      />
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className="text-center mt-4 mb-4"
                    >
                      No volunteer roles found for this event
                    </Typography>
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
